import React, { useEffect, useState } from "react";
import Dashboard from "./Components/dashboard/Dashboard";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes, Route, redirect } from "react-router-dom";
import SignIn from "./Auth/SignIn/SignIn";
import ForgetPassword from "./Auth/Forget-Password/ForgetPassword";
import { useCookies } from "react-cookie";
import ProtectRoute from "./Authenticate/ProtectRoute";
import RequestNewPassword from "./Auth/RequestNewPassword/RequestNewPassword";
import ResetPassword from "./Auth/ResetPassword/ResetPassword";
import PageNotFound from "./Pages/404Page/PageNotFound";
import MainMenu from "./Components/Menu/Menu";

export default function App() {
  const [cookies] = useCookies(["token"]);
  const cookie = cookies.token;
  const [isInvalidUrl, setIsInvalidUrl] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (cookie) {
      if (
        location.pathname == "/signIn" ||
        location.pathname == "/forgetPassword" ||
        location.pathname == "/requestNewPassword" ||
        location.pathname == "/resetPassword"
      ) {
        return navigate("/");
      }
    }

    if (location.hash.length !== 0) {
      setIsInvalidUrl(true);
    }

    console.log("location", location);

    autoRedirect();
  });

  const autoRedirect = () => {
    if (!cookie) {
      return redirect("/signIn");
    }
  };

  const invalidURLState = () => {
    setIsInvalidUrl(false);
  };

  return (
    <>
      {isInvalidUrl == true ? (
        <>
          <PageNotFound invalidUrlState={invalidURLState} />
        </>
      ) : (
        <Routes>
          <Route path="/" element={<ProtectRoute Component={Dashboard} />} />
          {!cookie && (
            <>
              <Route path="/signIn" element={<SignIn />} />
              <Route path="/forgetPassword" element={<ForgetPassword />} />
              <Route
                path="/requestNewPassword"
                element={<RequestNewPassword />}
              />
              <Route path="/resetPassword" element={<ResetPassword />} />
            </>
          )}
          <Route
            path="*"
            element={<PageNotFound invalidUrlState={invalidURLState} />}
          />
        </Routes>
      )}
    </>
  );
}
