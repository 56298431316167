import React from "react";
import MainMenu from "../../Components/Menu/Menu";
import "./PageNotFound.css";
import { useNavigate } from "react-router-dom";
import CrossImage from "../../assets/X.png";

interface PageNotFoundProps {
  invalidUrlState: () => void;
}

const PageNotFound: React.FC<PageNotFoundProps> = ({ invalidUrlState }) => {
  const navigate = useNavigate();

  const handleOnBackToHome = () => {
    invalidUrlState();
    navigate("/");
  };

  return (
    <>
      <MainMenu />
      <div className="notFoundMainDiv">
        <div className="row1">
          <img src={CrossImage} className="notFoundImg" alt="not found" />
          <h1 className="text404">404</h1>
        </div>
        <div>
          <h2 className="sorryText">Sorry, page not found</h2>
        </div>
        <div>
          <button className="notFoundButton" onClick={handleOnBackToHome}>
            Back to home
          </button>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
