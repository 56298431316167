import { Amplify, Auth } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

// AmplifyJS complains in the event that the browser session storage is empty,
// which it may be in incognito mode, upon creation of a new browser user
// profile, and under other conditions. Here we set an arbitrary unique id
// for the session which ensures that storage is never entirely empty.
if (!localStorage.getItem("revanista-app-session-meta")) {
  const appSessionMeta = { id: uuidv4() };
  localStorage.setItem(
    "revanista-app-session-meta",
    JSON.stringify(appSessionMeta),
  );
}

export const config = Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
    mandatorySignIn: false,
    // oauth,
  },
  API: {
    endpoints: [
      {
        name: "yield",
        endpoint: process.env.REACT_APP_YIELD_API_URL,
        custom_header,
      },
      {
        name: "backend",
        endpoint: process.env.REACT_APP_BACKEND_API_URL,
        custom_header,
      },
    ],
  },
  storage: localStorage,
});

async function custom_header(): Promise<object> {
  return {
    Authorization: await getJwtToken(),
  };
}

async function getJwtToken(): Promise<string> {
  const session = await Auth.currentSession();

  return session.getIdToken().getJwtToken();
}
